import React, {useContext, useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {PAContext} from "../../../Services/PAContext";

import {Chip} from "@mui/material";
import {cancelChipStyle} from "../../ButtonStyle/Cancel";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import {
    Timeline,
    TimelineContent,
    TimelineDot,
    TimelineItem,
    TimelineOppositeContent,
    TimelineSeparator
} from "@mui/lab";
import './itinerary.css';
import {capitalizeFirstLetter} from "../../Helpers/TextStyling";


function ItineraryViewSection(props){

    const navigate = useNavigate();

    const { dataArray } = useContext(PAContext);
    const { Planning } = dataArray;

    const [sortedItems, setSortedItems] = useState([]);

    useEffect(() => {
        console.log("change the view to : "+props.itineraryViewSection);

        /*
            this is either going to look like 2024-09-25 for dates or day 2 for days
         */

        if (!Planning || !Planning.activities || !Planning.meals || !Planning.lodging) {
            return;
        }

        // Filter activities based on view type
        const filteredActivities = Planning.activities.filter(activity => {
            if (props.itineraryViewType === 'date') {
                // Ensure time_table.dates exists and is not an empty array
                if (activity.time_table?.dates?.length > 0) {
                    return activity.time_table.dates[0]['date'] === props.itineraryViewSection
                        || activity.time_table.dates[activity.time_table.dates.length - 1]['date'] === props.itineraryViewSection;
                }
                return false;
            } else {
                // Ensure time_table.days exists and is not an empty array
                if (activity.time_table?.days?.length > 0) {
                    return Object.keys(activity.time_table.days[0]).includes(props.itineraryViewSection)
                        || Object.keys(activity.time_table.days[activity.time_table.days.length - 1]).includes(props.itineraryViewSection);
                }
                return false;
            }
        });

        // Filter meals based on view type
        const filteredMeals = Planning.meals.filter(meal => {
            if (props.itineraryViewType === 'date') {
                // Ensure time_table.dates exists and is not an empty array
                if (meal.time_table?.dates?.length > 0) {
                    return meal.time_table.dates[0]['date'] === props.itineraryViewSection;
                }
                return false;
            } else {
                // Ensure time_table.days exists and is not an empty array
                if (meal.time_table?.days?.length > 0) {
                    return Object.keys(meal.time_table.days[0]).includes(props.itineraryViewSection);
                }
                return false;
            }
        });


        // Filter lodgings based on view type
        const filteredLodgings = Planning.lodging.filter(lodging => {
            console.log(" lodging time_table : " + JSON.stringify(lodging, null, '\t'));

            if (props.itineraryViewType === 'date') {
                // Ensure time_table.dates exists and is not an empty array
                if (lodging.time_table?.dates?.length > 0) {
                    return lodging.time_table.dates[0]['date'] === props.itineraryViewSection
                        || lodging.time_table.dates[lodging.time_table.dates.length - 1]['date'] === props.itineraryViewSection;
                }
                return false;
            } else {
                // Ensure time_table.days exists and is not an empty array
                if (lodging.time_table?.days?.length > 0) {
                    return Object.keys(lodging.time_table.days[0]).includes(props.itineraryViewSection)
                        || Object.keys(lodging.time_table.days[lodging.time_table.days.length - 1]).includes(props.itineraryViewSection);
                }
                return false;
            }
        });


        // Filter flights based on view type
        const filteredFlights = Planning.flights.filter(flight => {
            if (props.itineraryViewType === 'date') {
                // Check if the depart_timestamp matches the itineraryViewSection date
                return dayjs(flight.depart_timestamp).isSame(dayjs(props.itineraryViewSection, 'YYYY-MM-DD'), 'day');
            } else {
                // Ensure time_table.days exists and is not an empty array
                if (flight.time_table?.days?.length > 0) {
                    return Object.keys(flight.time_table.days[0]).includes(props.itineraryViewSection)
                        || Object.keys(flight.time_table.days[flight.time_table.days.length - 1]).includes(props.itineraryViewSection);
                }
                return false;
            }
        });


        // Combine and sort items
        const combinedItems = [
            ...filteredActivities.map(activity => ({ ...activity, type: 'activity' })),
            ...filteredMeals.map(meal => ({ ...meal, type: 'meal' })),
            ...filteredLodgings.map(lodging => ({
                ...lodging,
                type: 'lodging',
                time: dayjs(props.itineraryViewSection, 'YYYY-MM-DD').isSame(dayjs(lodging.begin_date, 'MM/DD/YYYY'), 'day') ? lodging.check_in : lodging.check_out
            })),
            ...filteredFlights.map(flight => ({ ...flight, type: 'flight' })),
        ];

        const sortedItems = combinedItems.sort((a, b) => {
            const getDateTime = (item) => {
                if (item.depart_timestamp) {
                    // Flights: use the depart_timestamp directly
                    return dayjs(item.depart_timestamp);
                } else if (item.date && item.time) {
                    // Meals: combine date and time
                    return dayjs(`${item.date} ${item.time}`, 'MM/DD/YYYY hh:mm A');
                } else if (item.begin_date && item.begin_time) {
                    // Activities: combine begin_date and begin_time
                    return dayjs(`${item.begin_date} ${item.begin_time}`, 'MM/DD/YYYY hh:mm A');
                } else if (item.begin_date && item.check_in) {
                    // Lodging: combine begin_date and check_in
                    return dayjs(`${item.begin_date} ${item.check_in}`, 'MM/DD/YYYY hh:mm A');
                }
                return dayjs(); // Default to current date/time if nothing matches
            };

            const aTime = getDateTime(a);
            const bTime = getDateTime(b);

            return aTime.isBefore(bTime) ? -1 : 1;
        });

        console.log("sortedItems : "+JSON.stringify(sortedItems));

        setSortedItems(sortedItems);

    }, [props.itineraryViewSection]);

    function handlePromptViewSectionClose() {
        props.setShowEventItemExtendedConfig(false);
        navigate('/Planner/Itinerary')
    }


    return (

        <div className="itinerary-section-container" >
            {/* show the header */}
            <div
                className="itinerary-section-header"
            >
                <div className="itinerary-section-header-text">
                    <h5>{
                        props.itineraryViewType === 'date' ? (
                            dayjs(props.itineraryViewSection).format('ddd, D MMM YYYY')
                        ) : (
                            capitalizeFirstLetter(props.itineraryViewSection)
                        )

                    }</h5>
                </div>
                <div className="itinerary-section-header-chip-holder">
                    <Chip
                        sx={cancelChipStyle}
                        label="Close"
                        size="small"
                        color="error"
                        onClick={handlePromptViewSectionClose}
                    />
                </div>
            </div>

            <div className="itinerary-section-items-container">
                {sortedItems.length === 0 ? (
                    <Typography variant="subtitle2" align="center" color="textSecondary">
                        The day is free to do whatever you enjoy!
                    </Typography>
                ) : (
                    <Timeline>
                        {sortedItems.map((item, index) => {
                            const isActivity = item.type === 'activity';
                            const isMeal = item.type === 'meal';
                            const isLodging = item.type === 'lodging';
                            const isFlight = item.type === 'flight';
                            const isLastItem = index === sortedItems.length - 1;

                            return (
                                <TimelineItem key={item.id}>
                                    <TimelineOppositeContent>
                                        <Typography variant="caption" color="textSecondary">
                                            {isLodging ? (
                                                // Check if itineraryViewSection is a date
                                                dayjs(props.itineraryViewSection, 'YYYY-MM-DD').isValid() ? (
                                                    // Compare itineraryViewSection with the first and last date in lodging.time_table.dates
                                                    dayjs(props.itineraryViewSection, 'YYYY-MM-DD').isSame(item.time_table?.dates?.[0]?.date, 'day') ? (
                                                        `Check In: ${item.check_in}`
                                                    ) : dayjs(props.itineraryViewSection, 'YYYY-MM-DD').isSame(item.time_table?.dates?.[item.time_table.dates.length - 1]?.date, 'day') ? (
                                                        `Check Out: ${item.check_out}`
                                                    ) : null
                                                ) : (
                                                    // Check if itineraryViewSection is a day (e.g., 'day 7')
                                                    props.itineraryViewSection === Object.keys(item.time_table?.days?.[0])[0] ? (
                                                        `Check In: ${item.check_in}`
                                                    ) : props.itineraryViewSection === Object.keys(item.time_table?.days?.[item.time_table.days.length - 1])[0] ? (
                                                        `Check Out: ${item.check_out}`
                                                    ) : null
                                                )
                                            ) : isActivity ? (
                                                `${item.begin_time} - ${item.end_time}`
                                            ) : isFlight ? (
                                                // Display flight departure and arrival time
                                                item.depart_timestamp ? (
                                                    `Depart: ${dayjs(item.depart_timestamp).format('h:mm A')}`
                                                ) : item.arrive_timestamp ? (
                                                    `Arrive: ${dayjs(item.arrive_timestamp).format('h:mm A')}`
                                                ) : null
                                            ) : (
                                                item.begin_time || item.time
                                            )}
                                        </Typography>

                                    </TimelineOppositeContent>

                                    <TimelineSeparator>
                                        {isActivity ? (
                                            <TimelineDot color="success">
                                                <i className="icon-list-heart" style={{ fontSize: '1rem' }} /> {/* Adjust the size if necessary */}
                                            </TimelineDot>
                                        ) : isMeal ? (
                                            <TimelineDot color="info">
                                                <i className="icon-fork-knife2" style={{ fontSize: '1rem' }} /> {/* Adjust the size if necessary */}
                                            </TimelineDot>
                                        ) : isLodging ? (
                                            <TimelineDot color="warning">
                                                <i className="icon-bed" style={{ fontSize: '1rem' }} /> {/* Adjust the size if necessary */}
                                            </TimelineDot>
                                        ) : isFlight ? (
                                            <TimelineDot color="warning">
                                                <i className="icon-airplane" style={{ fontSize: '1rem' }} /> {/* Adjust the size if necessary */}
                                            </TimelineDot>
                                        ) : null}
                                    </TimelineSeparator>

                                    <TimelineContent>
                                        <Typography variant="subtitle2">
                                            {isActivity ? item.title : isMeal ? capitalizeFirstLetter(item.name) : isLodging ? item.location.name : item.depart_airport_city}
                                        </Typography>
                                        <Typography variant="caption">
                                            {
                                                isActivity ?
                                                    item.location.address
                                                    :
                                                    isMeal ?
                                                        item.location.name !== "" ? item.location.name : item.eat_in_details
                                                        :
                                                        isLodging ?
                                                            item.location.name : item.depart_airport_city
                                            }
                                        </Typography>
                                    </TimelineContent>
                                </TimelineItem>
                            );
                        })}
                    </Timeline>
                )}
            </div>



        </div>

    )
}

export default ItineraryViewSection;


