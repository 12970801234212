import React, {useContext, useEffect, useState} from 'react';
import {
    Avatar, Button, ButtonGroup, Card,
    CardActions,
    CardContent, CardHeader,
    CardMedia,
    Checkbox,
    Chip, Dialog, DialogActions, DialogContent, DialogContentText, Grid,
    ListItem,
    ListItemAvatar,
    Menu, MenuItem, MenuList, TextField
} from "@mui/material";
import Typography from "@mui/material/Typography";
import Badge from "@mui/material/Badge";

import dayjs from "dayjs";
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

import customParseFormat from 'dayjs/plugin/customParseFormat';
import {addChipStyle} from "../../ButtonStyle/Add";
import {DatePicker, TimePicker} from "@mui/x-date-pickers";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import currencyList from "../../../sampleData/currencyList";
import {PAContext} from "../../../Services/PAContext";
import Box from "@mui/material/Box";
import {
    Timeline,
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineItem,
    timelineItemClasses,
    TimelineSeparator
} from "@mui/lab";
import ListItemIcon from "@mui/material/ListItemIcon";
import TimeTable from "../../../MasterEventObjects/TimeTable";
import {editChipStyle} from "../../ButtonStyle/Edit";

dayjs.extend(isBetween);
dayjs.extend(customParseFormat);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

const CustomTimelineDot = ({ day }) => {
    // Parse and format the date using dayjs
    const formattedDay = dayjs(day, "MM/DD/YYYY").format('dd'); // Get the two-letter day abbreviation

    return (
        <Box
            sx={{
                width: 30,
                height: 30,
                borderRadius: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Typography variant="body2">{formattedDay}</Typography>
        </Box>
    );
};


function LodgingCard(props){

    const [anchorChangeCurrencyEl, setAnchorChangeCurrencyEl] = useState(null);

    const { dataArray, addPlanningAttendee } = useContext(PAContext);
    const { Planning, Attendees } = dataArray;

    const [eventLodging, setEventLodging] = useState(props.lodging);
    const [selectedAction, setSelectedAction] = useState("");
    const [lodgingTabSelected, setLodgingTabSelected] = useState("");

    const [showCalendarSelectBeginDate, setShowCalendarSelectBeginDate] = useState(false);
    const [showCalendarSelectEndDate, setShowCalendarSelectEndDate] = useState(false);
    const [showClockSelectCheckInTime, setShowClockSelectCheckInTime] = useState(false);
    const [showClockSelectCheckOutTime, setShowClockSelectCheckOutTime] = useState(false);

    const [showPriceOverride, setShowPriceOverride] = useState(false);
    const [checkShowPriceChanges, setCheckShowPriceChanges] = useState(false);
    const [showPriceChangesUpdateButton, setShowPriceChangesUpdateButton] = useState(false);

    useEffect(()=> {
        console.log("props.lodging has been updated --- : ");
        console.log(JSON.stringify(props.lodging, null, '\t'));
        setEventLodging(props.lodging);
    }, [props.lodging]);

    useEffect(() => {
        console.log("eventLodging has been updated --- : ");
        console.log(JSON.stringify(eventLodging, null, '\t'));
        console.log("--------------------------------");

        //send it up the chain
//        props.updateLodgingDetails(eventLodging);

    }, [eventLodging]);

    useEffect(()=> {
        if (Attendees !== null){
            Attendees.sort((a, b) => a.lname.localeCompare(b.lname));

            // Move lodging attendees to the top of the list
            for (let i = eventLodging.attendees.length - 1; i >= 0; i--) {
                const lodgingAttendee = eventLodging.attendees[i];
                const index = Attendees.findIndex(attendee => attendee.id === lodgingAttendee.id);
                if (index !== -1) {
                    const removedAttendee = Attendees.splice(index, 1)[0];
                    Attendees.unshift(removedAttendee);
                }
            }

        }

    }, [Attendees]);

    useEffect(()=> {
        console.log("..... checkShowPriceChanges");

        /*
            compare new pricing to see if anything needs to be saved
            this can be a change to the total price or the price breakdown
            for each Attendee
         */

        console.log("comparing props.lodging[amount_total] : "+props.lodging["amount_total"]+" and "+eventLodging["amount_total"]);
        if (props.lodging["amount_total"] !== eventLodging["amount_total"] || props.lodging["currency"] !== eventLodging["currency"]){
            //turn on the button

            console.log("the total cost or currency has changed");

            setShowPriceChangesUpdateButton(true);

        } else if (
            eventLodging["attendees"].some((_attendee, index) => {
                const initialAttendee = props.lodging["attendees"].find(a => a.id === _attendee.id);
                return initialAttendee && parseFloat(_attendee.cost) !== parseFloat(initialAttendee.cost);
            })) {
                //turn on the button

                console.log("an attendee cost has changed");

                setShowPriceChangesUpdateButton(true);
        } else {
            //no changes have been made
            console.log("no pricing changes to save");

            setShowPriceChangesUpdateButton(false);
        }

        setCheckShowPriceChanges(false);

    }, [checkShowPriceChanges]);

    const formatName = (name) => {
        // Capitalize the first letter and make the rest lowercase
        return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
    };
    function stringToColor(string) {
        let hash = 0;
        let i;

        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */

        return color;
    }
    function stringAvatar(name) {
        return {
            sx: {
                boxShadow: `0 0 5px ${stringToColor(name)}`,
                border: `1px solid ${stringToColor(name)}`,
                borderRadius: '10px',
                textAlign: 'center',
                fontWeight: '700',
                backgroundColor: '#FFF',
                color: 'black', // Text color (adjust as needed)
                fontSize: '15px'
            },
            children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
        };
    }

    function handlePromptShowLodgingBeginDateCalendar(){

        if (showCalendarSelectBeginDate){
            props.updateLodgingDetails(eventLodging);
        }

        //toggle the button
        setShowCalendarSelectBeginDate(!showCalendarSelectBeginDate);
    }
    function handlePromptShowLodgingEndDateCalendar(){

        if (showCalendarSelectEndDate){
//            props.updateLodgingDetails(eventLodging);
        }

        //toggle the button
        setShowCalendarSelectEndDate(!showCalendarSelectEndDate);
    }

    function handleBeginDateChange(begin_date){

        const newBeginDate = begin_date;

        setEventLodging(prevState => ({
            ...prevState,
            begin_date: dayjs(newBeginDate).format('MM/DD/YYYY')
        }));

    }
    function handleEndDateChange(end_date){
        console.log("handleEndDateChange");

        const newEndDate = end_date;

        setEventLodging(prevState => ({
            ...prevState,
            time_table: createTimeTableForLodging(eventLodging['begin_date'], dayjs(newEndDate).format('MM/DD/YYYY')),
            end_date: dayjs(newEndDate).format('MM/DD/YYYY')
        }));

        if (showCalendarSelectEndDate){
            props.updateLodgingDetails(eventLodging);

        }

    }

    // Function to generate a new TimeTable for the Lodging
    const createTimeTableForLodging = (begin_date, end_date) => {
        const timeTable = new TimeTable();

        // Parse the begin and end dates
        const start = dayjs(begin_date, "MM/DD/YYYY");
        const end = dayjs(end_date, "MM/DD/YYYY");

        // Check if dates are valid
        if (!start.isValid() || !end.isValid()) {
            console.error("Invalid dates provided for the lodging.");
            return timeTable;
        }

        // Generate dates between start and end dates
        let currentDate = start;
        while (currentDate.isSameOrBefore(end)) {
            timeTable.dates.push({
                date: currentDate.format("MM/DD/YYYY"),
                dayOfWeek: currentDate.format("dddd"),
                'all-day': true,
                notes: "",  // You can modify this as needed
            });
            currentDate = currentDate.add(1, 'day');  // Move to the next day
        }

        return timeTable;
    };

    function handlePromptShowLodgingCheckInTime(){
        //toggle the button

        //post the update
        if (showClockSelectCheckInTime){
            props.updateLodgingDetails(eventLodging);
        }

        //change button back to 'Edit'
        setShowClockSelectCheckInTime(!showClockSelectCheckInTime);
    }
    function handlePromptShowLodgingCheckOutTime(){
        //toggle the button

        //post the update
        if (showClockSelectCheckInTime){
            props.updateLodgingDetails(eventLodging);
        }

        //change button back to 'Edit'
        setShowClockSelectCheckOutTime(!showClockSelectCheckOutTime);
    }

    function handleCheckInTimeChange(check_in_time){
        console.log("check_in_time : "+dayjs(check_in_time).format('h:mm A'));
        setEventLodging(prevState => ({
            ...prevState,
            check_in: dayjs(check_in_time).format('h:mm A')
        }));

    }
    function handleCheckOutTimeChange(check_out_time){
        setEventLodging(prevState => ({
            ...prevState,
            check_out:  dayjs(check_out_time).format('h:mm A')
        }));

    }

    const handleOccupantsToggle = (_attendee_id) => () => {
        console.log("_attendee_id : "+_attendee_id);

        //see if this Attendee needs to be added or removed
        const index = eventLodging["attendees"].findIndex(item => item.id === _attendee_id);

        let updatedList = [...eventLodging["attendees"]];

        if (index !== -1) {
            // Remove attendee from lodging list
            updatedList.splice(index, 1);

//            removePlanningAttendee(_attendee_id);

        } else {

            //need to see if this Attendee has already been added to the Event
            //add if necessary
            const _att_index = Planning["attendees"].findIndex(att => att.id === _attendee_id);
            if (_att_index === -1){
                //need to add Attendee to the Event first
                addPlanningAttendee(Attendees.find((_att) => _att.id === _attendee_id));
            }

            // Add attendee to lodging list
            const _att_with_cost = { id: _attendee_id, cost: 0 };
            updatedList = [...eventLodging["attendees"], _att_with_cost];
        }

        if (eventLodging["assign_equal"]) {

            const lodgingCost = parseFloat(eventLodging["amount_total"]);
            const numberOfAttendees = updatedList.length;
            const baseCostPerAttendee = parseFloat(Math.floor((lodgingCost / numberOfAttendees) * 100) / 100).toFixed(2);
            const remainder = Math.round((lodgingCost - parseFloat(baseCostPerAttendee * numberOfAttendees).toFixed(2)) * 100) / 100;

            //go through and update the per Attendee cost
            updatedList.forEach((attendee, i) => {
                // Add the base cost to the attendee
                attendee.cost = baseCostPerAttendee;

                // Distribute any remainder to the last attendee
                if (i === numberOfAttendees - 1) {
                    attendee.cost = (parseFloat(remainder) + parseFloat(baseCostPerAttendee)).toFixed(2);
                }
            });

        }

        setEventLodging(prevState => ({
            ...prevState,
            attendees: updatedList
        }));

    };

    useEffect(() => {
        console.log('Attendees have been updated:', eventLodging.attendees);
        props.updateLodgingDetails(eventLodging);
    }, [eventLodging.attendees]);

    function handleCostOverride(event) {
        const valueToAdd = parseFloat(event.target.value);

        setEventLodging(prevState => ({
            ...prevState,
            amount_total: valueToAdd
        }));

    }
    function toggleShowOverride(){
        console.log("toggleShowOverride");

        if (showPriceOverride){
            //they are toggling to off so update Attendee costs

            if (eventLodging["assign_equal"]) {
                //update the amount assigned to each Occupant

                let updatedList = [...eventLodging["attendees"]];
                let lodgingCost = parseFloat(eventLodging["amount_total"]);
                const numberOfAttendees = updatedList.length;
                const baseCostPerAttendee = parseFloat(Math.floor((lodgingCost / numberOfAttendees) * 100) / 100).toFixed(2);
                console.log("baseCostPerAttendee : " + baseCostPerAttendee);
                console.log("occupants total : " + parseFloat(baseCostPerAttendee * numberOfAttendees).toFixed(2));
                const remainder = Math.round((lodgingCost - parseFloat(baseCostPerAttendee * numberOfAttendees).toFixed(2)) * 100) / 100;
                console.log("remainder : " + remainder);

                //go through and update the per Attendee cost
                updatedList.forEach((attendee, i) => {
                    // Add the base cost to the attendee
                    attendee.cost = baseCostPerAttendee;

                    // Distribute any remainder to the last attendee
                    if (i === numberOfAttendees - 1) {
                        attendee.cost = (parseFloat(remainder) + parseFloat(baseCostPerAttendee)).toFixed(2);
                    }

                });

                props.updateLodgingDetails(eventLodging);

            }
        }

        setShowPriceOverride(!showPriceOverride);
    }

    const openChangeCurrency = Boolean(anchorChangeCurrencyEl);
    const handleOpenChangeCurrency = (event) => {
        setAnchorChangeCurrencyEl(event.currentTarget);
    };
    const handleChangeCurrencyClick = (event, code) => {
        console.log("changing currency to : "+code);
        const _new_currency_code = code;
        setEventLodging(prevState => ({
            ...prevState,
            currency: _new_currency_code
        }));

        handleChangeCurrencyClose();
    };
    const handleChangeCurrencyClose = () => {
        setAnchorChangeCurrencyEl(null);
    };

    function handleToggleAssignEqual(){
        console.log("handleToggleAssignEqual");

        if (eventLodging["assign_equal"]){
            //they are changing to custom
            console.log("selecting custom costs");

            setEventLodging(prevState => ({
                ...prevState,
                assign_equal: false
            }));

        } else {
            //they are changing to equal
            console.log("selecting equal costs");

            let updatedList = [...eventLodging["attendees"]];

            const lodgingCost = parseFloat(eventLodging["amount_total"]);
            const numberOfAttendees = updatedList.length;
            const baseCostPerAttendee = parseFloat(Math.floor((lodgingCost / numberOfAttendees) * 100) / 100).toFixed(2);
            console.log("baseCostPerAttendee : " + baseCostPerAttendee);
            console.log("occupants total : " + parseFloat(baseCostPerAttendee * numberOfAttendees).toFixed(2));
            const remainder = Math.round((lodgingCost - parseFloat(baseCostPerAttendee * numberOfAttendees).toFixed(2)) * 100) / 100;
            console.log("remainder : " + remainder);

            //go through and update the per Attendee cost
            updatedList.forEach((attendee, i) => {
                // Add the base cost to the attendee
                attendee.cost = baseCostPerAttendee;

                // Distribute any remainder to the last attendee
                if (i === numberOfAttendees - 1) {
                    attendee.cost = (parseFloat(remainder) + parseFloat(baseCostPerAttendee)).toFixed(2);
                }
            });

            setEventLodging(prevState => ({
                ...prevState,
                attendees: updatedList,
                assign_equal: true
            }));

        }

        setCheckShowPriceChanges(true);

    }

    function handleCustomOccupantCost(_attendee_id, e){
        console.log("handleCustomOccupantCost : "+_attendee_id+" : "+e.target.value);


        //update this state variable
        setEventLodging(prevState => ({
            ...prevState,
            attendees: prevState.attendees.map(attendee => {
                if (attendee.id === _attendee_id) {
                    // Update the cost of the specific attendee
                    console.log("setting custom cost jjhgjhgjgjh : "+ (isNaN(parseFloat(e.target.value)) ? 0 : parseFloat(e.target.value)));
                    return { ...attendee, cost: e.target.value };
                }
                return attendee;
            })
        }));

        console.log("check to see if any pricing changes were made");
        setCheckShowPriceChanges(true);

        //update the Parent object
//        props.updateLodgingDetails(eventLodging);

        /*
                const updatedLodgingOccupantsCosts = lodgingOccupantsCosts.map((attendee) => {
                    if (attendee.id === _attendee_id) {
                        // Update the costs for the matching attendee
                        console.log("---- attendee : "+JSON.stringify(attendee));
                        return { ...attendee, costs: e.target.value };
                    }
                    console.log("---- attendee : "+JSON.stringify(attendee));
                    return attendee; // Keep other attendees unchanged
                });

                // Use the state updater function to work with the updated state
                setLodgingOccupantsCosts((prevCosts) => {

                    const _new_unassigned = parseFloat(lodgingCost - parseFloat(updatedLodgingOccupantsCosts.reduce((sum, attendee) => sum - -attendee.costs, 0)).toFixed(2));
                    console.log("_new_unassigned: " + _new_unassigned);
                    setLodgingOccupantsCostsUnassigned(_new_unassigned);

                    return updatedLodgingOccupantsCosts;
                });

         */

    }

    function handleSaveLodgingCostsAssignment(event){

        props.updateLodgingDetails(eventLodging);

        setShowPriceChangesUpdateButton(false);

    }

    function handleBookedThroughChange(event){
        const _newValue = event.target.value;
        setEventLodging(prevState => ({
            ...prevState,
            booked_through: _newValue
        }));
    }
    function handleConfirmationNumberChange(event){
        const _newValue = event.target.value;
        setEventLodging(prevState => ({
            ...prevState,
            confirmation_number: _newValue
        }));
    }
    function handleReservationNameChange(event){
        const _newValue = event.target.value;
        setEventLodging(prevState => ({
            ...prevState,
            reservation_name: _newValue
        }));
    }
    function handleReservationNumberChange(event){
        const _newValue = event.target.value;
        setEventLodging(prevState => ({
            ...prevState,
            reservation_number: _newValue
        }));
    }
    function handleGateCodeChange(event){
        const _newValue = event.target.value;
        setEventLodging(prevState => ({
            ...prevState,
            gate_code: _newValue
        }));
    }
    function handleDoorCodeChange(event){
        const _newValue = event.target.value;
        setEventLodging(prevState => ({
            ...prevState,
            door_code: _newValue
        }));
    }
    function handleWifiNameChange(event){
        const _newValue = event.target.value;
        setEventLodging(prevState => ({
            ...prevState,
            wifi_name: _newValue
        }));
    }
    function handleWifiPasswordChange(event){
        const _newValue = event.target.value;
        setEventLodging(prevState => ({
            ...prevState,
            wifi_password: _newValue
        }));
    }
    function handleNotesChange(event){
        const _newValue = event.target.value;
        setEventLodging(prevState => ({
            ...prevState,
            notes: _newValue
        }));
    }

    const handleFooterItemClick = (_item) => {
        setSelectedAction(_item);
        lodgingTabSelected === _item ? setLodgingTabSelected("") : setLodgingTabSelected(_item);
        setAnchorLodgingListItemMenu(null);
    };

    const [openDialogDateRange, setOpenDialogDateRange] = useState(false);
    function handleDialogDateRangeClose() {
        setOpenDialogDateRange(false);
    }
    function handleDialogDateRangeOK(){
        setOpenDialogDateRange(false);
    }

    const [anchorLodgingListItemMenu, setAnchorLodgingListItemMenu] = useState(null);
    const handleLodgingListItemMenuOpenMenu = (event) => {
        setAnchorLodgingListItemMenu(event.currentTarget);
    };
    const handleLodgingListItemMenuClick = (event) => {
        const target = event.currentTarget;
        const dataId = target.getAttribute('data-id');

        setAnchorLodgingListItemMenu(event.currentTarget);

    };
    const handleLodgingListItemMenuClose = () => {
        setAnchorLodgingListItemMenu(null);
    };




    // Check if time_table has dates or days
    const hasDates = Planning['time_table']?.dates && Planning['time_table']?.dates.length > 0;
    const hasDays = Planning['time_table']?.days && Planning['time_table']?.days.length > 0;
    console.log("hasDates : "+hasDates+" : hasDays : "+hasDays);



    return (

        <div>

            <Card
                sx={{
                    borderRadius: '5px',
                    border: '1px solid #f09637'
                }}
            >

                {/* add any photos if they exist */}
                {
                    eventLodging['photos'] && eventLodging['photos'].length >= 0 ? (
                        <div className="lodging-suggestions-add-list-item-photos">
                            {
                                eventLodging['photos'].map((photo, index) => (
                                    <CardMedia
                                        key={index}
                                        component="img"
                                        alt={`Attribution ${index + 1}`}
                                        image={photo} // Use the attribution as the image source
                                    />
                                ))
                            }
                        </div>
                    ) : (
                        <div className="lodging-suggestions-add-list-item-photos">

                            {
                                Object.keys(eventLodging.Duffel).length > 0 ? (
                                    <CardMedia
                                        sx={{ height: 100 }}
                                        image={eventLodging.Duffel['accommodation']['photos'][0].url}
                                        title="green iguana"
                                    />
                                ) : (
                                    Object.keys(eventLodging.Place).length > 0 ? (
                                        <div className="lodging-suggestions-add-list-item-photos">
                                            {eventLodging.photos.map((attribution, index) => (
                                                <CardMedia
                                                    key={index}
                                                    component="img"
                                                    alt={`Attribution ${index + 1}`}
                                                    image={attribution} // Use the attribution as the image source
                                                />
                                            ))}
                                        </div>
                                    ) : (
                                        <>No Photos</>
                                    )
                                )
                            }
                        </div>
                    )
                }

                <CardHeader
                    title={
                        (() => {
                            const timeTable = eventLodging['time_table'];

                            // Check if the time_table has dates
                            if (timeTable?.dates?.length > 0) {
                                const firstDate = dayjs(timeTable.dates[0].date, "YYYY-MM-DD");
                                const lastDate = dayjs(timeTable.dates[timeTable.dates.length - 1].date, "YYYY-MM-DD");

                                // If the dates are valid, format them as "Sat Jun 16 - Sun Jun 17" or "Sat - Sun"
                                if (firstDate.isValid() && lastDate.isValid()) {
                                    // If the month and year are the same, display the short format (days only)
                                    return `${firstDate.format('ddd, MMM D')} - ${lastDate.format('ddd, MMM D')}`;
                                }
                            }

                            // Fallback: Check time_table.days if no valid dates
                            if (timeTable?.days?.length > 0) {
                                const firstDayKey = Object.keys(timeTable.days[0])[0];
                                const lastDayKey = Object.keys(timeTable.days[timeTable.days.length - 1])[0];

                                const firstDay = timeTable.days[0][firstDayKey]; // e.g., 'Monday'
                                const lastDay = timeTable.days[timeTable.days.length - 1][lastDayKey]; // e.g., 'Wednesday'

                                // Return the day names
                                return `${firstDay} - ${lastDay}`;
                            }

                            // Fallback if no valid dates
                            return '';
                        })()
                    }
                    action={
                        <IconButton
                            aria-label="settings"
                            onClick={handleLodgingListItemMenuOpenMenu}
                        >
                            <i
                                className="icon-menu2"
                                style={{fontSize: '16px'}}
                            />
                        </IconButton>
                    }
                    sx={{ paddingBottom: '0' }}
                    titleTypographyProps={{ fontSize: '18px' }}
                />
                <Menu
                    sx={{ width: 320, maxWidth: '100%' }}
                    anchorEl={anchorLodgingListItemMenu}
                    id="lodging-item-menu"
                    open={Boolean(anchorLodgingListItemMenu)}
                    onClose={handleLodgingListItemMenuClose}

                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                    <MenuList
                        dense
                        sx={{ width: 320, maxWidth: '100%' }}
                    >
                        <MenuItem
                            data-id="dates"
                            onClick={() => handleFooterItemClick('Dates')}
                        >
                            <ListItemIcon>
                                <i className="icon-calendar3" title="Dates"/>
                            </ListItemIcon>
                            <ListItemText>Dates</ListItemText>
                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                {
                                    (() => {
                                        const beginDate = dayjs(eventLodging['begin_date']);
                                        const endDate = dayjs(eventLodging['end_date']);
                                        if (!beginDate.isValid() || !endDate.isValid()) {
                                            return '';
                                        }
                                        const diffDays = endDate.diff(beginDate, 'day');
                                        return diffDays === 1 ? `${diffDays} night` : `${diffDays} nights`;
                                    })()
                                }
                            </Typography>
                        </MenuItem>
                        <MenuItem
                            data-id="location"
                            onClick={() => handleFooterItemClick('Location')}
                        >
                            <ListItemIcon>
                                <i className="icon-map-marker" title="Location"/>
                            </ListItemIcon>
                            <ListItemText>Location</ListItemText>
                        </MenuItem>
                        <MenuItem
                            data-id="occupants"
                            onClick={() => handleFooterItemClick('Occupants')}
                        >
                            <ListItemIcon>
                                <i className="icon-user-24" title="Occupants"/>
                            </ListItemIcon>
                            <ListItemText>Occupants</ListItemText>
                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                {eventLodging['attendees'].length}
                            </Typography>
                        </MenuItem>
                        <MenuItem
                            data-id="costs"
                            onClick={() => handleFooterItemClick('Costs')}
                        >
                            <ListItemIcon>
                                <i className="icon-wallet" title="Costs"/>
                            </ListItemIcon>
                            <ListItemText>Costs</ListItemText>
                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                {parseFloat(eventLodging['amount_total']).toFixed(2)} {eventLodging['currency']}
                            </Typography>
                        </MenuItem>
                        <MenuItem
                            data-id="details"
                            onClick={() => handleFooterItemClick('Details')}
                        >
                            <ListItemIcon>
                                <i className="icon-checklist2" title="Details"/>
                            </ListItemIcon>
                            <ListItemText>Details</ListItemText>
                        </MenuItem>
                        <MenuItem
                            data-id="delete"
                            onClick={() => handleFooterItemClick('Delete')}
                        >
                            <ListItemIcon sx={{color:'#F00'}}>
                                <i className="icon-trash" title="Delete"/>
                            </ListItemIcon>
                            <ListItemText>Delete</ListItemText>
                        </MenuItem>
                    </MenuList>
                </Menu>

                {/* Dates and Night Count (beneath CardHeader) */}
                <Box display="flex" justifyContent="space-between" alignItems="center" sx={{padding:'0 16px 16px 16px'}}>
                    {/* Left-aligned: Dates */}

                    <Typography variant="body2" color="textSecondary">
                        {/*
                        {(() => {
                            const beginDate = dayjs(eventLodging['begin_date'], "MM/DD/YYYY");
                            const endDate = dayjs(eventLodging['end_date'], "MM/DD/YYYY");

                            // Check if the dates are valid
                            if (!beginDate.isValid() || !endDate.isValid()) {
                                return '';
                            }

                            // Format dates as "Aug 3 - Aug 6"
                            const formattedBeginDate = beginDate.format('MMM D');
                            const formattedEndDate = endDate.format('MMM D');

                            return `${formattedBeginDate} - ${formattedEndDate}`;
                        })()}
                        */}
                    </Typography>


                    {/* Right-aligned: Night Count */}
                    <Typography variant="body2" color="primary" sx={{ textAlign: 'right' }}>
                        {(() => {
                            const beginDate = dayjs(eventLodging['begin_date'], "MM/DD/YYYY");
                            const endDate = dayjs(eventLodging['end_date'], "MM/DD/YYYY");

                            // Check if the dates are valid
                            if (!beginDate.isValid() || !endDate.isValid()) {
                                return '';
                            }

                            // Calculate the number of nights
                            const diffDays = endDate.diff(beginDate, 'day');

                            return diffDays === 1 ? '1 Night' : `${diffDays} Nights`;
                        })()}
                    </Typography>
                </Box>

                <CardContent sx={{padding:0}}>

                    <Timeline
                        sx={{
                            [`& .${timelineItemClasses.root}:before`]: {
                                flex: 0,
                                padding: 0,
                            },
                            margin: 0
                        }}
                    >
                        <TimelineItem sx={{marginTop:0}}>
                            <TimelineSeparator>
                                {
                                    eventLodging['begin_date'] === "" ? (
                                        <TimelineDot color="primary" />
                                    ) : (
                                        <TimelineDot
                                            sx={{
                                                border: '1px solid #cbe0f7',
                                                bgcolor: 'transparent',
                                                color: '#151269'
                                            }}
                                        >
                                            <CustomTimelineDot day={eventLodging['begin_date']} />
                                        </TimelineDot>
                                    )
                                }
                                <TimelineConnector sx={{ bgcolor: '#cbe0f7' }}/>
                            </TimelineSeparator>

                            <TimelineContent sx={{py: '12px', px: 2}}>
                                <div>
                                    <Typography component="div">
                                        {eventLodging['check_in']} Check In
                                    </Typography>
                                </div>
                                <div style={{paddingLeft: '10px'}}>
                                    <Typography variant="body2">
                                        {eventLodging['name']}
                                    </Typography>
                                    <Typography sx={{mb: 1.5, fontSize: '12px'}} color="text.secondary">
                                        {eventLodging['location'].address}
                                        <br />
                                        {eventLodging['location'].city} {eventLodging['location'].state} {eventLodging['location'].zip}
                                    </Typography>
                                </div>
                            </TimelineContent>

                        </TimelineItem>

                        <TimelineItem>
                            <TimelineSeparator>
                                {
                                    eventLodging['end_date'] === "" ? (
                                        <TimelineDot color="primary" />
                                    ) : (
                                        <TimelineDot
                                            sx={{
                                                border: '1px solid #cbe0f7',
                                                bgcolor: 'transparent',
                                                color: '#151269'
                                            }}
                                        >
                                            <CustomTimelineDot day={eventLodging['end_date']} />
                                        </TimelineDot>
                                    )
                                }
                            </TimelineSeparator>
                            <TimelineContent sx={{py: '12px', px: 2}}>
                                <Typography component="div">
                                    {eventLodging['check_out']} Check Out
                                </Typography>
                            </TimelineContent>
                        </TimelineItem>

                    </Timeline>

                </CardContent>

                <CardActions sx={{paddingTop:'0', marginBottom:'10px'}}>
                    <div className="lodging-suggestions-add-list-item-footer-container">
                        {/* add the stay dates */}
                        <div
                            style={{backgroundColor: selectedAction === "Dates" ? '#EDEDED' : 'initial'}}
                            className="lodging-suggestions-add-list-item-footer-costs-container"
                            onClick={() => handleFooterItemClick('Dates')}
                        >
                            <i className="icon-calendar3" title="Dates"/>
                            {/*
                            <span className="lodging-suggestions-add-list-item-footer-costs-tab-amount">
                                {
                                    (() => {
                                        const beginDate = dayjs(eventLodging['begin_date']);
                                        const endDate = dayjs(eventLodging['end_date']);
                                        if (!beginDate.isValid() || !endDate.isValid()) {
                                            return '';
                                        }
                                        const diffDays = endDate.diff(beginDate, 'day');
                                        return diffDays === 1 ? `${diffDays} night` : `${diffDays} nights`;
                                    })()
                                }
                            </span>
                            */}
                        </div>
                        {/* add the lodging location */}
                        <div
                            style={{backgroundColor: selectedAction === "Location" ? '#EDEDED' : 'initial'}}
                            className="lodging-suggestions-add-list-item-footer-location-container"
                            onClick={() => handleFooterItemClick('Location')}
                        >
                            <i className="icon-map-marker" title="Location"/>
                        </div>
                        {/* add the stay occupants */}
                        <div
                            style={{backgroundColor: selectedAction === "Occupants" ? '#EDEDED' : 'initial'}}
                            className="lodging-suggestions-add-list-item-footer-occupants-container"
                            onClick={()=>handleFooterItemClick('Occupants')}
                        >
                            <Badge
                                badgeContent={eventLodging['attendees'].length}
                                color="badge"
                                showZero
                            >
                                <i className="icon-user-24"  title="Occupants"/>
                            </Badge>
                        </div>
                        {/* add the stay costs */}
                        <div
                            style={{backgroundColor: selectedAction === "Costs" ? '#EDEDED' : 'initial'}}
                            className="lodging-suggestions-add-list-item-footer-costs-container"
                            onClick={()=>handleFooterItemClick('Costs')}
                        >
                            <i className="icon-wallet"  title="Costs"/>
                            <span className="lodging-suggestions-add-list-item-footer-costs-tab-amount">
                                {parseFloat(eventLodging['amount_total']).toFixed(2)}
                            </span>
                        </div>
                        {/* add the stay details */}
                        <div
                            style={{backgroundColor: selectedAction === "Details" ? '#EDEDED' : 'initial'}}
                            className="lodging-suggestions-add-list-item-footer-details-container"
                            onClick={()=>handleFooterItemClick('Details')}
                        >
                            <Badge
                                badgeContent={0}
                                color="badge"
                            >
                                <i className="icon-settings-icons"  title="Details"/>
                            </Badge>
                        </div>
                    </div>
                </CardActions>

                <CardContent sx={{padding:'20px 0', display:lodgingTabSelected?'block':'none'}}>
                    {
                        selectedAction === "Dates" ? (
                            <div className="lodging-suggestions-add-list-item-footer-dates-holder">
                                {/* for the checkin date */}
                                <div
                                    className="lodging-suggestions-add-list-item-footer-dates-checkin"
                                >
                                    {
                                        eventLodging['begin_date'] && eventLodging['begin_date'].trim() !== "" ? (
                                            <>
                                                Check In :&nbsp;
                                                {dayjs(eventLodging['begin_date'], 'MM/DD/YYYY').format('D MMM, YYYY')}
                                                <Chip
                                                    sx={editChipStyle}
                                                    label={showCalendarSelectBeginDate ? 'Done' : 'Edit'}
                                                    size="small"
                                                    onClick={handlePromptShowLodgingBeginDateCalendar}
                                                />
                                            </>
                                        ) : (
                                            <>
                                                Check In :&nbsp;
                                                <Chip
                                                    sx={editChipStyle}
                                                    label={showCalendarSelectBeginDate ? 'Done' : 'Edit'}
                                                    size="small"
                                                    onClick={handlePromptShowLodgingBeginDateCalendar}
                                                />
                                            </>
                                        )
                                    }
                                </div>
                                <div
                                    className="lodging-suggestions-add-list-item-footer-dates-checkin-input"
                                    style={{display: showCalendarSelectBeginDate ? 'block' : 'none'}}>
                                    <DatePicker
                                        sx={{ width: '100%' }}
                                        label="Check In Date"
                                        value={dayjs(eventLodging['begin_date'], 'MM/DD/YYYY')}
                                        onChange={handleBeginDateChange}
                                    />
                                </div>

                                {/* for the checkin time */}
                                <div
                                    className="lodging-suggestions-add-list-item-footer-times-checkin"
                                >
                                    Time :&nbsp;
                                    {
                                        eventLodging['check_in']
                                    }
                                    <Chip
                                        sx={editChipStyle}
                                        label={showClockSelectCheckInTime?'Done':'Edit'}
                                        size="small"
                                        onClick={handlePromptShowLodgingCheckInTime}
                                    />
                                </div>
                                <div
                                    className="lodging-suggestions-add-list-item-footer-times-checkin-input"
                                    style={{display: showClockSelectCheckInTime ? 'block' : 'none'}}>
                                    <TimePicker
                                        sx={{ width: '100%' }}
                                        label="Check In Time"
                                        value={dayjs(eventLodging['check_in'], 'h:mm A')}
                                        onChange={handleCheckInTimeChange}
                                    />
                                </div>


                                <div
                                    className="lodging-suggestions-add-list-item-footer-dates-checkout"
                                >
                                    {
                                        eventLodging['end_date'] && eventLodging['end_date'].trim() !== "" ? (
                                            <>
                                                Check Out :&nbsp;
                                                {dayjs(eventLodging['end_date'], 'MM/DD/YYYY').format('D MMM, YYYY')}
                                                <Chip
                                                    sx={editChipStyle}
                                                    label={showCalendarSelectEndDate ? 'Done' : 'Edit'}
                                                    size="small"
                                                    onClick={handlePromptShowLodgingEndDateCalendar}
                                                />
                                            </>
                                        ) : (
                                            <>
                                                Check Out :&nbsp;
                                                <Chip
                                                    sx={addChipStyle}
                                                    label={showCalendarSelectEndDate ? 'Done' : 'Edit'}
                                                    size="small"
                                                    onClick={handlePromptShowLodgingEndDateCalendar}
                                                />
                                            </>
                                        )
                                    }
                                </div>
                                <div
                                    className="lodging-suggestions-add-list-item-footer-dates-checkout-input"
                                    style={{display: showCalendarSelectEndDate ? 'block' : 'none'}}>
                                    <DatePicker
                                        sx={{ width: '100%' }}
                                        label="Check Out Date"
                                        value={dayjs(eventLodging['end_date'], 'MM/DD/YYYY')}
                                        minDate={dayjs(eventLodging['begin_date'], 'MM/DD/YYYY')}
                                        onChange={handleEndDateChange}
                                    />
                                </div>

                                {/* for the checkout time */}
                                <div
                                    className="lodging-suggestions-add-list-item-footer-times-checkout"
                                >
                                    Time :&nbsp;
                                    {
                                        eventLodging['check_out']
                                    }
                                    <Chip
                                        sx={editChipStyle}
                                        label={showClockSelectCheckOutTime?'Done':'Edit'}
                                        size="small"
                                        onClick={handlePromptShowLodgingCheckOutTime}
                                    />
                                </div>
                                <div
                                    className="lodging-suggestions-add-list-item-footer-times-checkout-input"
                                    style={{display: showClockSelectCheckOutTime ? 'block' : 'none'}}>
                                    <TimePicker
                                        sx={{ width: '100%' }}
                                        label="Check Out Time"
                                        value={dayjs(eventLodging['check_out'], 'h:mm A')}
                                        onChange={handleCheckOutTimeChange}
                                    />
                                </div>


                            </div>
                        ) : (
                            <></>
                        )
                    }
                    {
                        selectedAction === "Location" ? (
                            <div className="lodging-suggestions-add-list-item-footer-location-holder">
                                <Grid container spacing={2} direction="column" sx={{ marginTop: '0' }}>
                                    <Grid item style={{ flex: '1' }}>
                                        <Grid container spacing={2} direction="column">
                                            <Grid item>
                                                <TextField
                                                    fullWidth
                                                    id="new_lodging_location_name"
                                                    label="Name"
                                                    value={eventLodging['location'].name}
                                                    onChange={handleBookedThroughChange}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <TextField
                                                    fullWidth
                                                    id="new_lodging_location_address"
                                                    label="Address"
                                                    value={eventLodging['location'].address}
                                                    onChange={handleBookedThroughChange}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <TextField
                                                    fullWidth
                                                    id="new_lodging_location_city"
                                                    label="City"
                                                    value={eventLodging['location'].city}
                                                    onChange={handleBookedThroughChange}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <TextField
                                                    fullWidth
                                                    id="new_lodging_location_state"
                                                    label="State"
                                                    value={eventLodging['location'].state}
                                                    onChange={handleBookedThroughChange}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <TextField
                                                    fullWidth
                                                    id="new_lodging_location_zip"
                                                    label="Zip"
                                                    value={eventLodging['location'].zip}
                                                    onChange={handleBookedThroughChange}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <TextField
                                                    fullWidth
                                                    id="new_activity_location_country"
                                                    label="Country"
                                                    value={eventLodging['location'].country}
                                                    onChange={handleBookedThroughChange}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <TextField
                                                    fullWidth
                                                    id="new_lodging_location_phone"
                                                    label="Phone"
                                                    value={eventLodging['location'].phone}
                                                    onChange={handleBookedThroughChange}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </div>
                        ) : (
                            <></>
                        )
                    }
                    {
                        selectedAction === "Occupants" && (
                            Attendees.length > 0 ? (
                                <List dense sx={{ width: '100%' }} >
                                    {/* Filter out activity attendees and sort by name */}
                                    {Attendees.filter(_attendee => Planning['attendees'].some(_att => _att.id === _attendee.id && _att.status !== 4))
                                        .sort((a, b) => `${formatName(a.lname)}, ${formatName(a.fname)}`.localeCompare(`${formatName(b.lname)}, ${formatName(b.fname)}`))
                                        .map((_attendee, index) => (
                                            <div key={index}>
                                                <ListItem
                                                    secondaryAction={
                                                        <Checkbox
                                                            edge="end"
                                                            onChange={handleOccupantsToggle(_attendee.id)}
                                                            checked={eventLodging['attendees'].some(_att => _att.id === _attendee.id)}
                                                            inputProps={{ 'aria-labelledby': index }}
                                                        />
                                                    }
                                                    disablePadding
                                                >
                                                    <ListItemButton>
                                                        <ListItemAvatar>
                                                            <Avatar {...stringAvatar(`${formatName(_attendee.fname)}, ${formatName(_attendee.lname)}`)} />
                                                        </ListItemAvatar>
                                                        <ListItemText
                                                            id={index}
                                                            primary={`${formatName(_attendee.lname)}, ${formatName(_attendee.fname)}`}
                                                        />
                                                    </ListItemButton>
                                                </ListItem>
                                                <Divider />
                                            </div>
                                        ))
                                    }

                                    <Box mt={6} mb={1}>
                                        <Divider />
                                    </Box>

                                    {/* Filter out non-activity attendees and sort by name */}
                                    {Attendees.filter(_attendee => !Planning['attendees'].some(_att => (_att.id === _attendee.id && _att.status !== 4)))
                                        .sort((a, b) => `${formatName(a.lname)}, ${formatName(a.fname)}`.localeCompare(`${formatName(b.lname)}, ${formatName(b.fname)}`))
                                        .map((_attendee, index) => (
                                            <div key={index}>
                                                <ListItem
                                                    secondaryAction={
                                                        <Checkbox
                                                            edge="end"
                                                            onChange={handleOccupantsToggle(_attendee.id)}
                                                            checked={eventLodging['attendees'].some(_att => _att.id === _attendee.id)}
                                                            inputProps={{ 'aria-labelledby': index }}
                                                        />
                                                    }
                                                    disablePadding
                                                >
                                                    <ListItemButton>
                                                        <ListItemAvatar>
                                                            <Avatar {...stringAvatar(`${formatName(_attendee.fname)}, ${formatName(_attendee.lname)}`)} />
                                                        </ListItemAvatar>
                                                        <ListItemText
                                                            id={index}
                                                            primary={`${formatName(_attendee.lname)}, ${formatName(_attendee.fname)}`}
                                                        />
                                                    </ListItemButton>
                                                </ListItem>
                                                <Divider />
                                            </div>
                                        ))
                                    }
                                </List>
                            ) : (
                                <div style={{ fontSize: '12px', textAlign: 'center' }}>
                                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                        <i className="icon-warning" style={{ color: '#FF0000' }} /> Add Attendees to Event first
                                    </Typography>
                                </div>
                            )
                        )
                    }
                    {
                        selectedAction === "Costs" ? (
                            <div className="lodging-suggestions-add-list-item-footer-costs-holder">
                                {/* Cost FlightSummary */}
                                <Typography variant="body2">
                                    Cost Summary
                                </Typography>
                                <div
                                    className="lodging-suggestions-add-list-item-footer-costs-header"
                                >
                                    {
                                        dayjs(eventLodging['end_date']).diff(dayjs(eventLodging['begin_date']), 'day')
                                    }
                                    &nbsp;night
                                    {
                                        dayjs(eventLodging['end_date']).diff(dayjs(eventLodging['begin_date']), 'day')===1?'':'s'
                                    }
                                    &nbsp;at&nbsp;
                                    {
                                        (eventLodging['amount_total']/dayjs(eventLodging['end_date']).diff(dayjs(eventLodging['begin_date']), 'day')).toFixed(2)
                                    }
                                    /night
                                </div>
                                <div
                                    className="lodging-suggestions-add-list-item-footer-costs-total-holder"
                                >
                                    <div style={{cursor:'pointer'}} onClick={toggleShowOverride}>
                                        <Chip
                                            sx={editChipStyle}
                                            label={showPriceOverride?'Done':'Edit'}
                                            size="small"
                                        />
                                    </div>
                                    <div className="lodging-suggestions-add-list-item-footer-costs-total">
                                        Total:&nbsp;
                                        {parseFloat(eventLodging['amount_total']).toFixed(2)}
                                        <IconButton
                                            sx={{fontSize: '12px', color:'initial'}}
                                            aria-label="more"
                                            id="long-button"
                                            aria-controls={openChangeCurrency ? 'long-menu' : undefined}
                                            aria-expanded={openChangeCurrency ? 'true' : undefined}
                                            aria-haspopup="true"
                                            onClick={handleOpenChangeCurrency}
                                        >
                                            {eventLodging['currency']}
                                        </IconButton>
                                        <Menu
                                            id="long-menu"
                                            MenuListProps={{
                                                'aria-labelledby': 'long-button',
                                            }}
                                            anchorEl={anchorChangeCurrencyEl}
                                            open={openChangeCurrency}
                                            onClose={handleChangeCurrencyClose}
                                        >
                                            {currencyList.map((option) => (
                                                <MenuItem sx={{fontSize:'12px'}} key={option.code} selected={option.code === eventLodging['currency']} onClick={(e)=>handleChangeCurrencyClick(e, option.code)}>
                                                    {option.code} : {option.name}
                                                </MenuItem>
                                            ))}
                                        </Menu>

                                    </div>
                                </div>
                                <div
                                    className="lodging-suggestions-add-list-item-footer-costs-override-holder"
                                    style={{display:showPriceOverride?'block':'none'}}
                                >
                                    <div
                                        className="lodging-suggestions-add-list-item-footer-costs-override-input-holder"
                                    >
                                        <TextField
                                            type="number"
                                            label="Lodging Total"
                                            value={eventLodging["amount_total"]}
                                            onChange={handleCostOverride}
                                        />
                                    </div>
                                </div>

                                <Divider />

                                {/* _new_lodging is the Assignment area */}
                                <div className="lodging-suggestions-add-list-item-footer-cost-assignment-holder" >
                                    <Typography variant="body2">
                                        Cost Assignment
                                    </Typography>
                                    {
                                        Planning.attendees.length === 0 ? (
                                            <div
                                                className="lodging-suggestions-add-list-item-footer-cost-assignment-no-attendees"
                                            >
                                                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                                    <i className="icon-warning" style={{color:'#FF0000'}} /> Add Attendees to Event first
                                                </Typography>
                                            </div>
                                        ) : (

                                            <div className="lodging-suggestions-add-list-item-footer-cost-assignment-holder">
                                                {/* _new_lodging is the toggle section */}
                                                <div className="lodging-suggestions-add-list-item-footer-cost-assignment-toggle-holder">
                                                    <ButtonGroup variant="text" aria-label="outlined button group">
                                                        <Button
                                                            style={{
                                                                fontSize: '11px',
                                                                backgroundColor: eventLodging['assign_equal']
                                                                    ? 'rgba(198, 226, 255, 0.8)'
                                                                    : 'initial'
                                                            }}
                                                            onClick={handleToggleAssignEqual}
                                                        >
                                                            Divide Evenly
                                                        </Button>
                                                        <Button
                                                            style={{
                                                                fontSize: '11px',
                                                                backgroundColor: !eventLodging['assign_equal']
                                                                    ? 'rgba(198, 226, 255, 0.8)'
                                                                    : 'initial'
                                                            }}
                                                            onClick={handleToggleAssignEqual}
                                                        >
                                                            Custom
                                                        </Button>
                                                    </ButtonGroup>
                                                </div>

                                                {/* _new_lodging is the attendee section */}
                                                <List dense sx={{ width: '100%'}} >
                                                    {
                                                        eventLodging['attendees']
                                                            .sort((a, b) => {
                                                                const _a = Attendees.find((_att)=>_att.id === a.id);
                                                                const _b = Attendees.find((_att)=>_att.id === b.id);
                                                                return _a.lname.localeCompare(_b.lname);
                                                            })
                                                            .map((_attendee, index) => (
                                                                <div
                                                                    className="lodging-suggestions-add-list-item-footer-cost-assignment-occupant-cost-holder"
                                                                    key={index}
                                                                >
                                                                    <ListItem
                                                                        className="lodging-suggestions-add-list-item-footer-cost-assignment-occupant-cost-list-item"
                                                                        secondaryAction={
                                                                            eventLodging['assign_equal'] ? (
                                                                                <span className="lodging-suggestions-add-list-item-footer-cost-assignment-occupant-cost">
                                                                                {_attendee.cost}&nbsp;{eventLodging['currency']}
                                                                            </span>
                                                                            ) : (
                                                                                <span className="lodging-suggestions-add-list-item-footer-cost-assignment-occupant-edit-cost">
                                                                                <TextField
                                                                                    label={
                                                                                        formatName(Attendees.find((_att)=>_att.id === _attendee.id).lname)
                                                                                        + ', ' +
                                                                                        formatName(Attendees.find((_att)=>_att.id === _attendee.id).fname)
                                                                                    }
                                                                                    value={_attendee.cost}
                                                                                    onChange={(e)=>handleCustomOccupantCost(_attendee.id, e)}
                                                                                    variant="standard"
                                                                                />
                                                                            </span>
                                                                            )
                                                                        }
                                                                        disablePadding
                                                                    >
                                                                        <ListItemAvatar>
                                                                            {Attendees.find((_att) => _att.id === _attendee.id) && ( // Check if the Attendee is found
                                                                                <Avatar {...stringAvatar(`${formatName(Attendees.find((_att) => _att.id === _attendee.id).fname)}, ${formatName(Attendees.find((_att) => _att.id === _attendee.id).lname)}`)} />
                                                                            )}
                                                                        </ListItemAvatar>

                                                                        <ListItemText
                                                                            sx={{ display: eventLodging['assign_equal'] ? 'block' : 'none' }}
                                                                            id={index}
                                                                            primary={Attendees.find((_att) => _att.id === _attendee.id) ? // Check if Attendee is found
                                                                                `${formatName(Attendees.find((_att) => _att.id === _attendee.id).lname)}, ${formatName(Attendees.find((_att) => _att.id === _attendee.id).fname)}`
                                                                                : '' // Return empty string if Attendee is not found
                                                                            }
                                                                        />

                                                                    </ListItem>
                                                                    <Divider />
                                                                </div>

                                                            ))
                                                    }
                                                </List>

                                                {/* show the unassigned */}
                                                <div className="lodging-suggestions-add-list-item-footer-cost-assignment-unassigned-holder">
                                                    <Typography sx={{ mb: 1.5, flex:'1' }}  variant="body2">
                                                        Unassigned Amount
                                                    </Typography>
                                                    <span className="lodging-suggestions-add-list-item-footer-cost-assignment-unassigned-amount">
                                                    {
                                                        (parseFloat(eventLodging['amount_total']) - eventLodging['attendees'].reduce((total, attendee) => {
                                                            const cost = parseFloat(attendee['cost']);
                                                            // If cost is NaN (including when it's an empty string), use 0 instead
                                                            return total + (isNaN(cost) ? 0 : cost);
                                                        }, 0)).toFixed(2)
                                                    }
                                                        &nbsp;
                                                        {
                                                            eventLodging['currency']
                                                        }
                                                    </span>

                                                </div>

                                                {/* show the update button if changes were made */}

                                                {
                                                    showPriceChangesUpdateButton ? (
                                                        <div style={{cursor: 'pointer'}} onClick={handleSaveLodgingCostsAssignment}>
                                                            <Chip
                                                                sx={addChipStyle}
                                                                label='Save Changes'
                                                                size="small"
                                                            />
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )
                                                }


                                            </div>

                                        )
                                    }

                                </div>

                            </div>
                        ) : (
                            <></>
                        )
                    }
                    {
                        selectedAction === "Details" ? (
                            <Grid container spacing={2} direction="column" sx={{ marginTop: '0' }}>
                                <Grid item style={{ flex: '1' }}>
                                    <Grid container spacing={2} direction="column">
                                        <Grid item>
                                            <TextField
                                                fullWidth
                                                id="new_lodging_booked_through"
                                                label="Booked Through"
                                                value={eventLodging['booked_through']}
                                                onChange={handleBookedThroughChange}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                fullWidth
                                                id="new_lodging_confirmation_number"
                                                label="Confirmation Number"
                                                value={eventLodging['confirmation_number']}
                                                onChange={handleConfirmationNumberChange}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                fullWidth
                                                id="new_lodging_reservation_name"
                                                label="Reservation Name"
                                                value={eventLodging['reservation_name']}
                                                onChange={handleReservationNameChange}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                fullWidth
                                                id="new_lodging_reservation_number"
                                                label="Reservation Number"
                                                value={eventLodging['reservation_number']}
                                                onChange={handleReservationNumberChange}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                fullWidth
                                                id="new_lodging_gate_code"
                                                label="Gate Code"
                                                value={eventLodging['gate_code']}
                                                onChange={handleGateCodeChange}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                fullWidth
                                                id="new_lodging_door_code"
                                                label="Door Code"
                                                value={eventLodging['door_code']}
                                                onChange={handleDoorCodeChange}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                fullWidth
                                                id="new_lodging_wifi_name"
                                                label="Wifi Name"
                                                value={eventLodging['wifi_name']}
                                                onChange={handleWifiNameChange}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                fullWidth
                                                id="new_lodging_wifi_password"
                                                label="Wifi Password"
                                                value={eventLodging['wifi_password']}
                                                onChange={handleWifiPasswordChange}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                fullWidth
                                                id="new_lodging_notes"
                                                label="Notes"
                                                multiline
                                                rows={4}
                                                value={eventLodging['notes']}
                                                onChange={handleNotesChange}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                        ) : (
                            <></>
                        )
                    }
                </CardContent>
            </Card>

            <Dialog
                open={openDialogDateRange}
                onClose={handleDialogDateRangeClose}
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        The date for the Lodging is outside of the date range for the Event.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogDateRangeOK} autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )

}

export default LodgingCard;
