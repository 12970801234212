import {browserStorageUpdateSpecificEvent} from "../../../Services/BrowserDatabaseFunctions";
import {useContext} from "react";
import {PAContext} from "../../../Services/PAContext";
import {convertEventVersion1To2} from "../../Helpers/EventConverter";


function mergeChanges(existing, changes) {
    if (!Array.isArray(existing)) {
        existing = [];
    }

    if (Array.isArray(changes)) {
        const changeIds = new Set(changes.map(change => change.id));

        // Remove items from existing that are not in changes
        for (let i = existing.length - 1; i >= 0; i--) {
            if (!changeIds.has(existing[i].id)) {
                existing.splice(i, 1);
            }
        }

        // Add or update items from changes to existing
        changes.forEach(change => {
            const index = existing.findIndex(item => item.id === change.id);
            if (index !== -1) {
                existing[index] = change; // Update existing item
            } else {
                existing.push(change); // Add new item
            }
        });
    }
}

function E_getEventChangesResults(
    _incoming,
    _dataarray_events,
    _dataarray_planning,
    setDataArray
) {
    console.log("E_getEventChangesResults : " + JSON.stringify(_incoming, null, '\t'));

    if (_incoming.result === 'ok' && _incoming.events?.length > 0) {
        console.log("Event(s) need updating: " + _incoming.events.length);

        const updatedEvents = [..._dataarray_events];
        let updatedPlanning = { ..._dataarray_planning };

        _incoming.events.forEach((_evt) => {

            const _convertedEvent = convertEventVersion1To2(_evt);

            browserStorageUpdateSpecificEvent(_convertedEvent)
                .then(() => {
                    console.log("Updated browser storage for: " + _convertedEvent.name);
                    console.log("updatedEvents: " + updatedEvents);
                    // Update or add event in Events array
                    const eventIndex = updatedEvents.findIndex((event) => event.id === _convertedEvent.id);

                    console.log("eventIndex : "+eventIndex);

                    if (eventIndex !== -1) {
                        updatedEvents[eventIndex] = _convertedEvent;
                    } else {
                        updatedEvents.push(_convertedEvent);
                    }

                    // Update Planning if the event matches
                    if (updatedPlanning.id === _convertedEvent.id) {
                        console.log("Updating Planning object with event details");

                        // Update specific fields
                        Object.assign(updatedPlanning, {
                            title: _convertedEvent.title,
                            description: _convertedEvent.description,
                            eventIntro: _convertedEvent.eventIntro,
                            coverImage: _convertedEvent.coverImage,
                            image: _convertedEvent.image,
                            cover_image_thumbnail: _convertedEvent.cover_image_thumbnail,
                            host: _evt.host,
                            budget_total: _convertedEvent.budget_total,
                            budget_currency: _convertedEvent.budget_currency,
                            budget_actual: _convertedEvent.budget_actual,
                        });

                        mergeChanges(updatedPlanning.time_table, _convertedEvent.time_table);
                        mergeChanges(updatedPlanning.attendees, _convertedEvent.attendees);
                        mergeChanges(updatedPlanning.activities, _convertedEvent.activities);
                        mergeChanges(updatedPlanning.lodging, _evt.lodging);
                        mergeChanges(updatedPlanning.meals, _convertedEvent.meals);
                        mergeChanges(updatedPlanning.ideas, _convertedEvent.ideas);
                        mergeChanges(updatedPlanning.flights, _convertedEvent.flights);
                        mergeChanges(updatedPlanning.car_rentals, _convertedEvent.car_rentals);
                        mergeChanges(updatedPlanning.cruises, _convertedEvent.cruises);
                        mergeChanges(updatedPlanning.supplies, _convertedEvent.supplies);
                        mergeChanges(updatedPlanning.to_do, _convertedEvent.to_do);
                        mergeChanges(updatedPlanning.documents, _convertedEvent.documents);
                        mergeChanges(updatedPlanning.receipts, _convertedEvent.receipts);
                        mergeChanges(updatedPlanning.messages, _convertedEvent.messages);

                        mergeChanges(updatedPlanning.menu_items, _convertedEvent.menu_items);
                        mergeChanges(updatedPlanning.weather, _convertedEvent.weather);
                        mergeChanges(updatedPlanning.suggestions, _convertedEvent.suggestions);

                        /*
                            if any new photos were added then we need to get those
                            or if some were deleted then they need to be removed
                         */

                        mergeChanges(updatedPlanning.photos, _convertedEvent.photos);

                        console.log("the updated event : "+JSON.stringify(updatedPlanning, null, '\t'));
                    }


                    // Update React state
                    setDataArray((prevDataArray) => ({
                        ...prevDataArray,
                        Events: updatedEvents,
                        Planning: updatedPlanning,
                    }));
                })
                .catch((error) => {
                    console.error("Error updating browser storage for event: " + _convertedEvent.title, error);
                });
        });
    } else {
        console.log("No Events need updating or result is not ok");
    }
}

function E_getEventChangesResults1(_incoming, _dataarray_events, _dataarray_planning) {
    console.log("E_getEventChangesResults : "+JSON.stringify(_incoming, null, '\t'));

    console.log("*****************************888");
    console.log("*****************************888");
    console.log("the _dataarray_events : "+JSON.stringify(_dataarray_events));
    console.log("*****************************888");
    console.log("*****************************888");
    console.log("*****************************888");
    console.log("the _dataarray_planning : "+JSON.stringify(_dataarray_planning));
    console.log("*****************************888");

    /*
        this brings in the results of the Photo upload
        the full size image should already be here and the generated thumbnail will be coming once the
        resizing process is complete
    */

    if (_incoming.result === 'ok') {
        if (_incoming.events && _incoming.events.length > 0) {

            console.log("Event(s) need updating: " + _incoming.events.length);

            _incoming.events.forEach(_evt => {

                browserStorageUpdateSpecificEvent(_evt).then(r => {
                    console.log("Back from updating browser storage for: " + _evt.name);
                    console.log("_planning.id === _evt.id? : " + _dataarray_planning.id + " : "+ _evt.id);

                    //update the dataArray.Events
                    const eventIndex = _dataarray_events.findIndex(event => event.id === _evt.id);

                    if (eventIndex !== -1) {
                        // If the event exists, update it
                        _dataarray_events[eventIndex] = _evt;
                    } else {
                        // If the event does not exist, add it
                        _dataarray_events.push(_evt);
                    }


                    if (_dataarray_planning.id === _evt.id) {
                        console.log("the update was for the Event they're working on");
                        // See exactly what needs to be updated
                        // Check each part of the Event
                        _dataarray_planning.title = _evt.name;
                        _dataarray_planning.description = _evt.description;
                        _dataarray_planning.eventIntro = _evt.eventIntro;
                        _dataarray_planning.coverImage = _evt.coverImage;
                        _dataarray_planning.image = _evt.image;
                        _dataarray_planning.cover_image_thumbnail = _evt.cover_image_thumbnail;
                        _dataarray_planning.host = _evt.host;
                        _dataarray_planning.budget_total = _evt.budget_total;
                        _dataarray_planning.budget_currency = _evt.budget_currency;
                        _dataarray_planning.budget_actual = _evt.budget_actual;

                        mergeChanges(_dataarray_planning.attendees, _evt.attendees);
                        mergeChanges(_dataarray_planning.activities, _evt.activities);
                        mergeChanges(_dataarray_planning.lodging, _evt.lodging);
                        mergeChanges(_dataarray_planning.meals, _evt.meals);
                        mergeChanges(_dataarray_planning.ideas, _evt.ideas);
                        mergeChanges(_dataarray_planning.flights, _evt.flights);
                        mergeChanges(_dataarray_planning.car_rentals, _evt.car_rentals);
                        mergeChanges(_dataarray_planning.cruises, _evt.cruises);
                        mergeChanges(_dataarray_planning.supplies, _evt.supplies);
                        mergeChanges(_dataarray_planning.to_do, _evt.to_do);
                        mergeChanges(_dataarray_planning.documents, _evt.documents);
                        mergeChanges(_dataarray_planning.receipts, _evt.receipts);
                        mergeChanges(_dataarray_planning.messages, _evt.messages);

                        mergeChanges(_dataarray_planning.menu_items, _evt.menu_items);
                        mergeChanges(_dataarray_planning.weather, _evt.weather);
                        mergeChanges(_dataarray_planning.suggestions, _evt.suggestions);

                        /*
                            if any new photos were added then we need to get those
                            or if some were deleted then they need to be removed
                         */

                        mergeChanges(_dataarray_planning.photos, _evt.photos);



                        console.log("Planning object updated with event details:", _dataarray_planning);
                    } else {
                        console.log("the update was NOT for the Event they're working on");
                    }
                }).catch(error => {
                    console.error("Error updating browser storage for event: " + _evt.name, error);
                });
            });
        } else {
            console.log("No Events need updating");
        }

    } else {
        // Handle problems getting the updated Events list
        console.error("Problems getting the updated Events list");
    }
    
}

export default E_getEventChangesResults;



export function E_setEventAllChangesResults(_incoming, _planning){
    console.log("E_setEventAllChangesResults : "+JSON.stringify(_incoming, null, '\t'));

    if (_incoming.result === 'ok') {
        //update the
        browserStorageUpdateSpecificEvent(_planning).then(_success => {
            if (_success){
                console.log("successful save to browser database");
            } else {
                console.log("problems saving Event to browser database");
            }

        });

    } else {
        // Handle problems getting the updated Events list
        console.error("Problems getting the updated Events list");
    }

}

export function E_updateEventNameResults(_incoming,
                                         _dataarray_events,
                                         _dataarray_planning,
                                         setDataArray){

    console.log("new event name : "+JSON.stringify(_incoming));

    if (_incoming.result === 'ok' && _incoming.event_name.length > 0) {
        console.log("Event name needs updating: " + _incoming.event_name.length);

        const updatedEvents = [..._dataarray_events];
        let updatedPlanning = {..._dataarray_planning};

        const eventIndex = updatedEvents.findIndex((event) => event.id === _incoming.event_id);

        console.log("eventIndex : " + eventIndex);

        if (eventIndex !== -1) {
            updatedEvents[eventIndex].title = _incoming.event_name;

            browserStorageUpdateSpecificEvent(updatedEvents[eventIndex])
                .then(() => {
                    // Update Planning if the event matches
                    if (updatedPlanning.id === _incoming.event_id) {
                        console.log("Updating Planning object with event name");
                        updatedPlanning.title = _incoming.event_name;
                    }

                    // Update React state
                    setDataArray((prevDataArray) => ({
                        ...prevDataArray,
                        Events: updatedEvents,
                        Planning: updatedPlanning,
                    }));
                });
        }
    }
}