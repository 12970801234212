import PAEvent from "../../MasterEventObjects/PAEvent";
import TimeTable from "../../MasterEventObjects/TimeTable";
import dayjs from "dayjs";
import Attendee from "../../MasterEventObjects/Attendee";
import Lodging from "../../MasterEventObjects/Lodging";
import {
    createTimeTableForActivity, createTimeTableForFlight,
    createTimeTableForLodging,
    createTimeTableForMeal
} from "./EventTimeTableConversion";
import PALocation from "../../MasterEventObjects/Location";
import Activity from "../../MasterEventObjects/Activity";
import Meal from "../../MasterEventObjects/Meal";
import Idea from "../../MasterEventObjects/Idea";
import Flight from "../../MasterEventObjects/Flight";
import {useContext} from "react";
import {PAContext} from "../../Services/PAContext";

export function convertEventVersion1To2(specificEvent){

    const _event = new PAEvent();
    _event.id = specificEvent.id;
    _event.title = specificEvent.name ? specificEvent.name : specificEvent.title;
    _event.coverImage = specificEvent.coverImage;
    _event.cover_image_thumbnail = specificEvent.cover_image_thumbnail;

    //convert the calendar if necessary
    const _existing_calendar = specificEvent.calendar;
    if (_existing_calendar){
        const _time_table = new TimeTable();

        const beginDate = dayjs(_existing_calendar.begin_date);
        const endDate = dayjs(_existing_calendar.end_date);

        const dateObjects = [];
        const daysOfWeekSet = new Set(); // To store unique days of the week

        // Iterate through each day between begin_date and end_date
        let currentDate = beginDate;
        while (currentDate.isBefore(endDate) || currentDate.isSame(endDate, 'day')) {
            const dateObj = {
                date: currentDate.format('YYYY-MM-DD'),
                dayOfWeek: currentDate.format('dddd'),
                'all-day': true, // Assuming it's an all-day event by default
                notes: '', // Initialize notes as an empty string
            };

            // Add the unique day of the week to the Set
            daysOfWeekSet.add(currentDate.format('dddd'));

            // Check if the day is the first day and if it's not an all-day event
            if (currentDate.isSame(beginDate, 'day') && (beginDate.hour() !== 0 || beginDate.minute() !== 0)) {
                dateObj['all-day'] = false;
            }

            // Check if the day is the last day and if it's not an all-day event
            if (currentDate.isSame(endDate, 'day') && (endDate.hour() !== 23 || endDate.minute() !== 59)) {
                dateObj['all-day'] = false;
            }

            // Set begin-time and end-time if they exist
            if (currentDate.isSame(beginDate, 'day')) {
                dateObj['begin-time'] = beginDate.format('h:mm A');
            }
            if (currentDate.isSame(endDate, 'day')) {
                dateObj['end-time'] = endDate.format('h:mm A');
            }

            dateObjects.push(dateObj);
            currentDate = currentDate.add(1, 'day'); // Move to the next day
        }

        // Update _time_table with dates
        _time_table.dates = dateObjects;

        // Populate _time_table.days with the unique days of the week from daysOfWeekSet
        _time_table.days = Array.from(daysOfWeekSet).map((dayOfWeek, index) => ({
            [`day ${index + 1}`]: dayOfWeek,
            'all-day': true,
            notes: '', // Initialize with empty notes
        }));

        _event.time_table = _time_table;

    } else {
        _event.time_table = specificEvent.time_table;
    }

    console.log("************ the _event.time_table : "+JSON.stringify(_event.time_table, null, '\t'));

    //convert the attendees if necessary
    if (specificEvent.attendees.budget === undefined){
        const updated_attendees = [];

        for (const _attendee of specificEvent.attendees){
            const _pa_attendee = new Attendee(
                _attendee.fname,
                _attendee.lname,
                _attendee.email
            );

            _pa_attendee.id = _attendee.id;
            _pa_attendee.role = _attendee.role;
            _pa_attendee.image = _attendee.image;
            _pa_attendee.nickname = _attendee.nickname;

            _pa_attendee.address = _attendee.address;
            _pa_attendee.city = _attendee.city;
            _pa_attendee.state = _attendee.state;
            _pa_attendee.zip = _attendee.zip;
            _pa_attendee.country = _attendee.country;

            _pa_attendee.phone_prefix = _attendee.phone_prefix;
            _pa_attendee.phone = _attendee.phone;
            _pa_attendee.mask_phone_number = _attendee.mask_phone_number;

            _pa_attendee.push_notifications = _attendee.push_notifications;
            _pa_attendee.push_keys = _attendee.push_keys;

            _pa_attendee.rsvp_sent = _attendee.rsvp_sent;
            _pa_attendee.rsvp = _attendee.rsvp;
            _pa_attendee.rsvp_notes = _attendee.rsvp_notes;

            _pa_attendee.notification = _attendee.notification;

            _pa_attendee.documents = _attendee.documents;

            _pa_attendee.last_check = _attendee.last_check;
            _pa_attendee.status = _attendee.status;

            updated_attendees.push(_pa_attendee);
        }
        _event.attendees = updated_attendees;
    } else {
        _event.attendees = specificEvent.attendees;
    }

    //convert the lodging if necessary
    const _existing_lodging = specificEvent.lodging;
    if (_existing_lodging && _existing_lodging.length > 0){

        if (_existing_lodging.location === undefined){
            //need to convert
            for (const _existing of _existing_lodging){
                const _new_lodging = new Lodging();
                _new_lodging.id = _existing.id;

                _new_lodging.name = _existing.name;

                _new_lodging.begin_date = _existing.begin_date;
                _new_lodging.end_date = _existing.end_date;
                _new_lodging.check_in = _existing.check_in;
                _new_lodging.check_out = _existing.check_out;

                //now figure out the time_table
                _new_lodging.time_table = createTimeTableForLodging(
                    _existing.begin_date,
                    _existing.end_date,
                    _event.time_table);


                //add the lodging location
                const _lodging_location = new PALocation();
                _lodging_location.google_place_id = _existing.google_place_id;
                _lodging_location.latitude = _existing.latitude;
                _lodging_location.longitude = _existing.longitude;

                _lodging_location.name = _existing.name;
                _lodging_location.formatted_address = "";
                _lodging_location.address = _existing.address;
                _lodging_location.city = _existing.city;
                _lodging_location.state = _existing.state;
                _lodging_location.zip = _existing.zip;
                _lodging_location.country = _existing.country;

                _new_lodging.location = _lodging_location;

                _new_lodging.phone = _existing.phone;

                _new_lodging.booked_through = _existing.booked_through;
                _new_lodging.confirmation_number = _existing.confirmation_number;
                _new_lodging.reservation_name = _existing.reservation_name;
                _new_lodging.reservation_number = _existing.reservation_name;
                _new_lodging.gate_code = _existing.gate_code;
                _new_lodging.door_code = _existing.door_code;
                _new_lodging.wifi_password = _existing.wifi_password;

                if (_existing.attendees !== undefined){
                    for (const _att of _existing.attendees){
                        if (_att.costs === undefined){
                            const _att_with_cost = {};
                            _att_with_cost.id = _att;
                            _att_with_cost.cost = 0;
                            _new_lodging.attendees.push(_att_with_cost);
                        } else {
                            _new_lodging.attendees.push(_att);
                        }

                    }
                }

                _new_lodging.amount_total = _existing.amount_total === undefined ? 0 : _existing.amount_total;
                _new_lodging.currency = _existing.currency === undefined ? "USD" : _existing.currency;

                _new_lodging.time_zone = _existing.time_zone;
                _new_lodging.notes = _existing.notes;
                _new_lodging.status = _existing.status;

                _event.lodging.push(_new_lodging);
            }
        } else {
            _event.lodging = specificEvent.lodging;
        }
    }

    //convert the activities if necessary
    const _existing_activities = specificEvent.activities;
    if (_existing_activities && _existing_activities.length > 0) {

        const _activities_list= _existing_activities.map(_activity => {

            if (_activity.amount_total === undefined) {
                console.log("the Event Activity neeeds to be converted");
                const _new_activity = new Activity();
                _new_activity.id = _activity.id;
                _new_activity.title = _activity.title;
                _new_activity.begin_date = _activity.begin_date;
                _new_activity.end_date = _activity.end_date;
                _new_activity.all_day_activity = _activity.all_day_activity;
                _new_activity.begin_time = _activity.begin_time;
                _new_activity.end_time = _activity.end_time;

                //now figure out the time_table
                _new_activity.time_table = createTimeTableForActivity(
                    _activity.begin_date,
                    _activity.end_date,
                    _activity.all_day_activity,
                    _activity.begin_time,
                    _activity.end_time,
                    _event.time_table);

                //add the activity location
                const _activity_location = new PALocation();
                _activity_location.google_place_id = _activity.location.google_place_id;
                _activity_location.latitude = _activity.location.latitude;
                _activity_location.longitude = _activity.location.longitude;

                _activity_location.name = _activity.location.name;
                _activity_location.formatted_address = "";
                _activity_location.address = _activity.location.address;
                _activity_location.city = _activity.location.city;
                _activity_location.state = _activity.location.state;
                _activity_location.zip = _activity.location.zip;
                _activity_location.country = _activity.location.country;

                _new_activity.location = _activity_location;

                for (const _att of _activity.attendees){
                    if (_att.costs === undefined){
                        const _att_with_cost = {};
                        _att_with_cost.id = _att;
                        _att_with_cost.cost = 0;
                        _new_activity.attendees.push(_att_with_cost);
                    } else {
                        _new_activity.attendees.push(_att);
                    }
                }

                _new_activity.phone = _activity.location.phone;
                _new_activity.booked_through = _activity.location.booked_through;
                _new_activity.confirmation_number = _activity.location.confirmation_number;
                _new_activity.reservation_number = _activity.location.reservation_number;
                _new_activity.reservation_name = _activity.location.reservation_name;

                _new_activity.details = _activity.details;
                _new_activity.bring = _activity.bring;

                _new_activity.amount_total = _activity.amount_total === undefined ? 0 : _activity.amount_total;
                _new_activity.currency = _activity.currency === undefined ? "USD" : _activity.currency;

                _new_activity.notes = _activity.notes;

                _new_activity.status = _activity.status;

                return _new_activity;

            } else {
                return _activity;
            }

        });
        _event.activities = _activities_list;

    }

    //convert the meals if necessary
    const _existing_meals = specificEvent.meals;
    if (_existing_meals && _existing_meals.length > 0) {

        const _meal_list= _existing_meals.map(_meal => {

            if (_meal.eatery_name || _meal.eatery_name === "") {
                // Convert old structure to new structure

                const _new_meal = new Meal();
                _new_meal.id = _meal.id;
                _new_meal.name = _meal.meal_name;
                _new_meal.date = _meal.meal_date;
                _new_meal.time = _meal.meal_time;

                //now figure out the time_table
                _new_meal.time_table = createTimeTableForMeal(
                    _meal.meal_date,
                    _meal.meal_time,
                    _event.time_table);

                //add the meal location
                const _meal_location = new PALocation();
                _meal_location.google_place_id = _meal.eatery_google_place_id;
                _meal_location.latitude = _meal.eatery_latitude;
                _meal_location.longitude = _meal.eatery_longitude;

                _meal_location.name = _meal.eatery_name;
                _meal_location.formatted_address = "";
                _meal_location.address = _meal.eatery_address;
                _meal_location.city = _meal.eatery_city;
                _meal_location.state = _meal.eatery_state;
                _meal_location.zip = _meal.eatery_zip;
                _meal_location.country = _meal.eatery_country;

                _new_meal.location = _meal_location;

                _new_meal.phone = _meal.eatery_phone;
                _new_meal.booked_through = _meal.booked_through;
                _new_meal.confirmation_number = _meal.confirmation_number;
                _new_meal.reservation_number = _meal.reservation_number;
                _new_meal.reservation_name = _meal.reservation_name;

                _new_meal.eat_in = _meal.eat_in;
                _new_meal.eat_in_details = _meal.eat_in_details;
                _new_meal.notes = _meal.eatery_notes;

                for (const _att of _meal.attendees) {
                    if (_att.costs === undefined) {
                        const _att_with_cost = {};
                        _att_with_cost.id = _att;
                        _att_with_cost.cost = 0;
                        _new_meal.attendees.push(_att_with_cost);
                    } else {
                        _new_meal.attendees.push(_att);
                    }
                }

                _new_meal.photos = [];
                _new_meal.amount_total = _meal.amount_total === undefined ? 0 : _meal.amount_total;
                _new_meal.currency = _meal.currency === undefined ? "USD" : _meal.currency;
                _new_meal.assign_equal = _meal.assign_equal === undefined ? true : _meal.assign_equal;

                _new_meal.status = _meal.status;

                return _new_meal;
            } else {
                return _meal;
            }

        });

        _event.meals = _meal_list;

    }

    //convert the ideas if necessary
    const _existing_ideas = specificEvent.ideas;
    if (_existing_ideas && _existing_ideas.length > 0) {

        if (_existing_ideas[0].location === undefined) {
            for (const _existing of _existing_ideas) {
                const _new_idea = new Idea();
                _new_idea.id = _existing.id;
                _new_idea.index = _existing.index;
                _new_idea.title = _existing.title;
                _new_idea.category = _existing.category;

                _new_idea.attendee_id = _existing.attendee_id;

                _new_idea.url = _existing.url;

                const _idea_location = new PALocation();
                _idea_location.google_place_id = _existing.google_place_id;
                _idea_location.latitude = _existing.latitude;
                _idea_location.longitude = _existing.longitude;

                _idea_location.name = _existing.name;
                _idea_location.formatted_address = "";
                _idea_location.address = _existing.address;
                _idea_location.city = _existing.city;
                _idea_location.state = _existing.state;
                _idea_location.zip = _existing.zip;
                _idea_location.country = _existing.country;

                _new_idea.location = _idea_location;

                _new_idea.phone = _existing.phone;

                _new_idea.comments = _existing.comments;

                _new_idea.status = _existing.status;

                _event.ideas.push(_new_idea);
            }
        } else {
            _event.ideas = specificEvent.ideas;
        }
    }

    //convert the flights if necessary
    const _existing_flights = specificEvent.flights;
    if (_existing_flights && _existing_flights.length > 0) {

        if (_existing_flights[0].amount_total === undefined) {
            for (const _existing of _existing_flights) {
                const _new_flight = new Flight();
                _new_flight.id = _existing.id;
                _new_flight.flight_group_id = _existing.flight_group_id;
                _new_flight.ident = _existing.ident;
                _new_flight.actual_ident = _existing.actual_ident;
                _new_flight.flight_number = _existing.flight_number;

                _new_flight.airline_code = _existing.airline_code;
                _new_flight.airline_name = _existing.airline_name;

                //now figure out the time_table
                _new_flight.time_table = createTimeTableForFlight(
                    _existing.depart_timestamp,
                    _existing.arrive_timestamp,
                    _event.time_table);

                _new_flight.flight_duration = _existing.flight_duration;

                _new_flight.depart_airport_code = _existing.depart_airport_code;
                _new_flight.depart_airport_name = _existing.depart_airport_name;
                _new_flight.depart_airport_city = _existing.depart_airport_city;
                _new_flight.depart_airport_latitude = _existing.depart_airport_latitude;
                _new_flight.depart_airport_longitude = _existing.depart_airport_longitude;
                _new_flight.depart_timestamp = _existing.depart_timestamp;
                _new_flight.depart_timezone = _existing.depart_timezone;
                _new_flight.depart_timezone_offset = _existing.depart_timezone_offset;

                _new_flight.arrive_airport_code = _existing.arrive_airport_code;
                _new_flight.arrive_airport_name = _existing.arrive_airport_name;
                _new_flight.arrive_airport_city = _existing.arrive_airport_city;
                _new_flight.arrive_airport_latitude = _existing.arrive_airport_latitude;
                _new_flight.arrive_airport_longitude = _existing.arrive_airport_longitude;
                _new_flight.arrive_timestamp = _existing.arrive_timestamp;
                _new_flight.arrive_timezone = _existing.arrive_timezone;
                _new_flight.arrive_timezone_offset = _existing.arrive_timezone_offset;

                if (_existing.attendees !== undefined){
                    for (const _att of _existing.attendees){
                        if (_att.costs === undefined){
                            const _att_with_cost = {};
                            _att_with_cost.id = _att;
                            _att_with_cost.cost = 0;
                            _new_flight.attendees.push(_att_with_cost);
                        } else {
                            _new_flight.attendees.push(_att);
                        }
                    }
                }

                _new_flight.amount_total = _existing.amount_total === undefined ? 0 : _existing.amount_total;
                _new_flight.currency = _existing.currency === undefined ? "USD" : _existing.currency;

                _new_flight.notes = "";
                _new_flight.status = _existing.status;

                _event.flights.push(_new_flight);
            }
        } else {
            _event.flights = specificEvent.flights;
        }
    }

    _event.supplies = specificEvent.supplies;
    _event.to_do = specificEvent.to_do;
    _event.documents = specificEvent.documents;
    _event.receipts = specificEvent.receipts;

    _event.messages = specificEvent.messages === undefined ? [] : specificEvent.messages;

    _event.photos = specificEvent.photos;

    _event.menu_items = specificEvent.menu_items;

    _event.need_to_save = specificEvent.need_to_save === undefined ? false : specificEvent.need_to_save;

    _event.version = 2.0;
    _event.last_mod = specificEvent.last_mod;
    _event.status = specificEvent.status;


    console.log("&&&&&&&&&&&& the _event.time_table : "+JSON.stringify(_event.time_table, null, '\t'));

    return _event;

}