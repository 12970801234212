import React, {useRef, useState} from 'react';
import {Chip, Dialog, DialogContent, DialogTitle} from "@mui/material";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import {useNavigate} from "react-router-dom";
import MasterAttendees from "./MasterAttendees";


function GettingStarted({ setShowEventItemConfig, isOpen, onClose }){

    const navigate = useNavigate();

    const topics = [
        { title: 'Let\'s fill in the basic details',
            options: ['Add dates/days', 'Add event photo', 'Add event name'] },
        { title: 'Invite people - Select how you want to add people to your event',
            options: ['From past event', 'Add new', 'Upload spreadsheet'] },
        { title: 'Where are you staying?',
            options: ['AirBnB', 'Hotel', 'Camping', 'Enter address', 'From GPS', 'Don\'t know yet, but I know the city and state'] },
        { title: 'Make your to-do list',
            options: ['Add new', 'Pull from traditional to-do items', 'Don\'t have any'] },
        { title: 'Start your packing list - Never forget essential items for your trip',
            options: ['Pull from essentials list', 'Add new', 'Get inspiration from suggested lists'] },
        { title: 'Have any activities planned yet?',
            options: ['Yes, let\'s add it!', 'Need suggestions please', 'Nothing yet'] },
        { title: 'Keep your travel expenses under control - set your budget',
            options: ['Yes please!', 'Maybe later'] },
    ];

    const handleClick = (topicIndex, optionIndex) => {
        console.log("selected : "+topicIndex+" : "+optionIndex);

        switch (topicIndex) {
            case 0: // "Let's fill in the basic details"
                if (optionIndex === 0) {
                    navigate('/Planner/Dates', { state: { showSidebar: true } }); // Add dates/days
                } else if (optionIndex === 1) {
                    // go to Details and then click on the Cover Image tab
                    navigate('/Planner/Details', { state: { selectedTab: 'Cover' } });
                } else if (optionIndex === 2) {
                    // go to Details and then click on the Name tab
                    navigate('/Planner/Details', { state: { selectedTab: 'Name' } });
                }
                break;

            case 1: // "Invite people"
                if (optionIndex === 0) {
                    navigate('/Planner/Attendees/AddPrevious');
                } else if (optionIndex === 1) {
                    navigate('/Planner/Attendees/AddNew');
                } else if (optionIndex === 2) {
                    setIsAttendeesDialogOpen(true);
                }
                break;

            case 2: // "Where are you staying?"
                if (optionIndex === 0) {
                    navigate('/event/lodging/airbnb'); // AirBnB
                }
                break;

            case 3: // "Make your to-do list"
                if (optionIndex === 0) {
                    navigate('/event/todo/add'); // Add new
                }
                break;

            case 4: // "Start your packing list"
                if (optionIndex === 0) {
                    navigate('/event/packing/essentials'); // Pull from essentials list
                }
                break;

            case 5: // "Have any activities planned yet?"
                if (optionIndex === 0) {
                    navigate('/event/activities/add'); // Add activity
                }
                break;

            case 6: // "Keep your travel expenses under control"
                if (optionIndex === 0) {
                    navigate('/event/budget/set'); // Yes please
                }
                break;

            default:
                console.error('Invalid topic or option selected.');
        }

        //close the Dialog
        onClose();

    };

    const dialogRefAttendees = useRef(null);
    const [isAttendeesDialogOpen, setIsAttendeesDialogOpen] = useState(false);
    const closeAttendeesDialog = () => {
        setIsAttendeesDialogOpen(false);
    };


    return (

        <>
            <Dialog open={isOpen} onClose={onClose}>
                <DialogTitle
                    sx={{
                        color: '#151269',
                        textAlign:'center',
                        backgroundColor:'rgba(198, 226, 255, 0.8)',
                        paddingRight: '30px'
                    }}
                >
                    Organize all your travel details in one place!
                </DialogTitle>
                <DialogTitle
                    sx={{
                        fontSize: '18px',
                        textAlign:'center',
                        color: '#151269',
                        backgroundColor:'rgba(198, 226, 255, 0.8)',
                        padding:0
                    }}
                >
                    We're here to help you get started
                </DialogTitle>
                <DialogContent>

                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={(theme) => ({
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: '#151269',
                        })}
                    >
                        <i className="icon-cross" style={{fontSize: '18px', padding: '5px'}}/>
                    </IconButton>

                    {
                        topics.map((topic, index) => (
                            <Box key={index} mx={1} my={1}>
                                {/* Horizontal Line + Topic + Horizontal Line */}
                                <Box display="flex" alignItems="center" justifyContent="center">
                                    <Divider sx={{ width: '20px', height: '1px', marginRight: '10px', backgroundColor: '#0096ff' }} />
                                    <Typography variant="subtitle2">{topic.title}</Typography>
                                    <Divider sx={{ width: '20px', height: '1px', marginLeft: '10px', flex: 1, backgroundColor: '#0096ff' }} />
                                </Box>
                                {/* Options below each topic */}
                                <Box display="flex" justifyContent="flex-start" mt={1} gap={1} flexWrap="wrap">
                                    {
                                        topic.options.map((option, idx) => (
                                            <Chip
                                                key={idx}
                                                sx={{
                                                    boxShadow: '' +
                                                        '0px 3px 1px -2px rgba(0, 0, 0, 0.2), ' +
                                                        '0px 2px 2px 0px rgba(0, 0, 0, 0.14), ' +
                                                        '0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
                                                    border: '1px solid #fff',
                                                    borderRadius: '10px',
                                                    textAlign: 'center',
                                                    backgroundColor: '#FFF',
                                                    color: 'black', // Text color (adjust as needed)
                                                    '&:hover': {
                                                        background: '#cbe0f7', // Reverse the gradient on hover
                                                        border: '1px solid #0096ff',
                                                    },
                                                }}
                                                label={option}
                                                onClick={() => handleClick(index, idx)}
                                                style={{margin: '0.5rem'}}
                                            />

                                        ))
                                    }
                                </Box>
                            </Box>
                        ))
                    }
                </DialogContent>
            </Dialog>

            {/* Contacts Dialog */}
            <MasterAttendees
                dialogRefAttendees={dialogRefAttendees}
                isAttendeesDialogOpen={isAttendeesDialogOpen}
                closeAttendeesDialog={closeAttendeesDialog}
                isEventDialogOpen={false}
                isUploadModeOn={true}
            />
        </>

    )

}

export default GettingStarted;