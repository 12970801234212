

class Photo {
    constructor(){

        this.event_id = "";
        this.id = 0;
        this.temp_id = 0;
        this.attendee_id = "";
        this.filename = "";
        this.filetype = "";
        this.height = 0;
        this.width = 0;
        this.thumbnail_data = "";
        this.full_data = "";
        this.caption = "";
        this.private = false;
        this.sharable = false;
        this.share_with = [];
        this.photo_album = false;
        this.full_size_uploaded = false;
        this.thumbnail_finished = false;
        this.last_modified = 0;
        this.status = 0;
    }

}

export default Photo;
